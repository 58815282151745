<div class="row">
	<div class="col-md-12">
		<div class="card">
			<div class="card-body">
				<form *ngIf="_travel" #editForm="ngForm" [formReset]="editForm" (ngSubmit)="editForm.valid && submit()">
					<div class="row">
						<div class="col-md-3">
							<h5>Dati smart</h5>
						</div>
					</div>
					<div class="row">
						<div class="form-group col-md-2">
							<label for="price">{{ 'labels.smart-price' | translate }}*</label>
							<input [(ngModel)]="_travel.price" #price="ngModel" name="price" required type="number"
								class="form-control" id="price" placeholder="{{ 'labels.smart-price' | translate }}"
								[ngClass]="{'is-invalid': editForm.submitted && price.invalid}"
								(ngModelChange)="priceChange($event)" >
							<div class="invalid-feedback" *ngIf="editForm.submitted && price.invalid">
								{{'errors.required' | translate}}
							</div>
						</div>
						<div class="form-group col-md-2">
							<label for="priceCreator">{{ 'labels.smart-price' | translate }}</label>
							<input [(ngModel)]="_travel.priceCreator" #priceCreator="ngModel" name="priceCreator" required type="number"
								class="form-control" id="price" placeholder="{{ 'labels.smart-price-creator' | translate }}"
								[ngClass]="{'is-invalid': editForm.submitted && price.invalid}" disabled>
							<div class="invalid-feedback" *ngIf="editForm.submitted && price.invalid">
								{{'errors.required' | translate}}
							</div>
						</div>
						<div class="form-group col-md-2">
							<label for="minguests">{{ 'labels.smart-minguests' | translate }}*</label>
							<input [(ngModel)]="_travel.minguests" #minguests="ngModel" name="minguests" required
								type="number" class="form-control" id="minguests"
								placeholder="{{ 'labels.smart-minguests' | translate }}"
								[ngClass]="{'is-invalid': editForm.submitted && minguests.invalid}">
							<div class="invalid-feedback" *ngIf="editForm.submitted && minguests.invalid">
								{{'errors.required' | translate}}
							</div>
						</div>
						<div class="form-group col-md-2">
							<label for="maxtimegoal">{{ 'labels.smart-maxtimegoal' | translate }}*</label>
							<input [(ngModel)]="_travel.maxtimegoal" #maxtimegoal="ngModel" name="maxtimegoal" required
								type="number" class="form-control" id="maxtimegoal"
								placeholder="{{ 'labels.smart-maxtimegoal' | translate }}"
								[ngClass]="{'is-invalid': editForm.submitted && maxtimegoal.invalid}">
							<div class="invalid-feedback" *ngIf="editForm.submitted && maxtimegoal.invalid">
								{{'errors.required' | translate}}
							</div>
						</div>
						<div class="form-group col-md-6">
							<label for="bookingDetail">{{ 'labels.smart-booking-detail' | translate }}*</label>
							<textarea #bookingDetail="ngModel" name="bookingDetail" rows="3" [maxlength]="256"
								class="form-control" id="bookingDetail" [(ngModel)]="_travel.bookingDetail"
								[ngClass]="{'is-invalid': editForm.submitted && bookingDetail.invalid}"></textarea>
							<div class="invalid-feedback" *ngIf="editForm.submitted && bookingDetail.invalid">
								{{'errors.required' | translate}}
							</div>
						</div>
					</div>
					<div class="row">
						<div class="form-group col-md-12">
							<label for="facilities">{{ 'labels.smart-facilities' | translate }}</label>
							<!-- <ng-template ngFor let-facility [ngForOf]="facilitiesMap | keyvalue"> -->
							<br>
							<span class="listitem" *ngFor="let facility of facilitiesMap | keyvalue">
								<input type="checkbox" [checked]="facilitiesMap[facility.key]"
									(click)="onClick(facility.key, facility.value)" disabled>
								{{facility.key}}
							</span>
							<!-- </ng-template> -->
						</div>
					</div>
					<br>
					<br>
					<div class="modal-footer">
						<!-- <button
					type="button"
					*ngIf="_travel?.isSubmitted"
					class="btn btn-success float-left"
					(click)="accept()">
					{{'labels.accept' | translate}}
				</button> -->
						<button type="submit" class="btn btn-primary">
							{{'labels.save' | translate}}
						</button>
						<button type="reset" class="btn btn-secondary">
							{{'labels.reset' | translate}}
						</button>
						<!-- <button
					*ngIf="_travel?.isSubmitted"
					type="button"
					(click)="decline()"
					class="btn btn-danger">
					{{'labels.decline' | translate}}
				</button> -->

					</div>
				</form>
			</div>
		</div>
	</div>
	<div class="col-md-12">
		<app-card
			[hidHeader]="true"
			cardClass="card-datatable"
			[options]="false">

			<div class="row">
				<div class="col-md-3">
					<h5>Transazioni</h5>
				</div>
			</div>
			<div class="table-responsive">

				<select
				[(ngModel)]="transactionType"
				(ngModelChange)="transactionTypeChange($event)"
				name="transactionType"
				class="form-control"
				id="transactionType"
				[multiple]="false"
				[disabled]="false">
					<option [defaultSelected]="true">ALL</option>
					<option [ngValue]="'APPROVED'">APPROVED</option>
					<option [value]="'PENDING'">PENDING</option>
					<option [value]="'REJECTED'">REJECTED</option>
				</select>
				<ngx-datatable
					[tablefix]="datatable"
					#datatable
					class="bootstrap"
					[rows]="transactions"
					[count]="transactions.length"
					[loadingIndicator]="loading"
					[columnMode]="'flex'"
					[rowHeight]="'auto'"
					[headerHeight]="50"
					[footerHeight]="30"
					[externalPaging]="true"
					[externalSorting]="true"
					[messages]="{
          emptyMessage: 'labels.empty-feedback' | translate,
          totalMessage: 'labels.total' | translate}">
					<ngx-datatable-column
						prop="id"
						[flexGrow]="0.5"
						[name]="'labels.id' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>
					<!-- <ngx-datatable-column
						prop="travelId"
						[flexGrow]="0.5"
						[name]="'labels.transaction-travel-id' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column> -->
					<ngx-datatable-column
						prop="stripeId"
						[flexGrow]="0.5"
						[name]="'labels.transaction-stripe-id' | translate"
						
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>
					<ngx-datatable-column
						prop="status"
						[flexGrow]="0.5"
						[sortable]="true"
						[name]="'labels.transaction-status' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>
					<ngx-datatable-column
						prop="userId"
						[flexGrow]="0.5"
						[name]="'labels.transaction-user-id' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							<a [routerLink]="'/user/edit/' + value">{{value}}</a>
						</ng-template>
					</ngx-datatable-column>
					<ngx-datatable-column
						prop="insdate"
						[flexGrow]="0.5"
						[name]="'labels.insdate' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value | date: 'dd/MM/yyyy'}}
						</ng-template>
					</ngx-datatable-column>
				</ngx-datatable>

			</div>
		</app-card>
	</div>

</div>