import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { plainToClass, classToPlain } from 'class-transformer';
import { AuthService, Staff } from 'elbuild-ui-lib-core';
import { mergeMap, map } from 'rxjs/operators';
import { Observable } from 'rxjs/Observable';

export class TravelTransaction {
    id: number;
    travelId: number;
    stripeId: string;
    status: string;
    userId: number;
    insdate: number;
}

@Injectable()
export class SmartTravelService {

    httpOptions: any;

    constructor(protected http: HttpClient) { }

    getSmartTravelTransactions(travelId: number): Observable<any> {
        return this.http
            .get(`/ws/travel/${travelId}/smart/guest/transactions`)
            .pipe(map(res => plainToClass(TravelTransaction, res as Object)));
    }

}

