<div class="row">
	<div class="col-sm-12">
		<app-smart-transactions-list-filters
			#listfilters
			(exportEv)="export()"
			[loading]="exporting"
			[searchparams]="advancedSearch">
		</app-smart-transactions-list-filters>
	</div>
</div>

<div class="row ">
	<div class="col-sm-12">
		<app-card
			[hidHeader]="true"
			cardClass="card-datatable"
			[options]="false">

			<app-loading
				[full]="false"
				*ngIf="loading"></app-loading>

			<div
				*ngIf="!loading"
				class="table-responsive">

				<ngx-datatable
					[tablefix]="datatable"
					#datatable
					class="bootstrap"
					[rows]="rows"
					[columnMode]="'flex'"
					[rowHeight]="'auto'"
					[headerHeight]="50"
					[footerHeight]="30"
					[externalPaging]="true"
					[externalSorting]="true"
					[loadingIndicator]="loading"
					[count]="page.totalElements"
					[offset]="page.pageNumber"
					[limit]="page.size"
					[rowClass]="rowClass"
					(page)="onPageChange($event)"
					(sort)="onSortChange($event)"
					[messages]="{
          emptyMessage: 'labels.empty-puchase' | translate,
          totalMessage: 'labels.total' | translate
		}">

					<ngx-datatable-column
						prop="checked"
						[flexGrow]="0.4"
						[name]="''"
						[sortable]="false"
						[resizeable]="false"
						[cellClass]="'text-center'">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<input
								type="checkbox"
								class="bootstrap mt-1"
								(click)="toggleCheckbox(row)"
								[checked]="value">
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="id"
						[flexGrow]="0.5"
						[name]="'labels.id' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="travelId"
						[flexGrow]="1"
						[name]="'labels.travel' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="userId"
						[flexGrow]="1"
						[sortable]="false"
						[name]="'labels.user' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							let-row="row"
							ngx-datatable-cell-template>
							<a routerLink="/user/edit/{{value}}">{{row.user?.fullname}} ({{value}})</a>
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="insdate"
						[flexGrow]="1"
						[name]="'labels.purchasedate' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value*1000 | date:"dd/MM/yyyy HH:mm"}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="stripeId"
						[flexGrow]="1"
						[name]="'labels.transaction-stripe-id' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>

					<ngx-datatable-column
						prop="status"
						[flexGrow]="1"
						[name]="'labels.status' | translate"
						[resizeable]="false">
						<ng-template
							let-value="value"
							ngx-datatable-cell-template>
							{{value}}
						</ng-template>
					</ngx-datatable-column>


				</ngx-datatable>

			</div>
		</app-card>
	</div>
</div>
